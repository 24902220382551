<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-row no-gutters>
                 <v-col cols="10"></v-col>
                 <v-col cols="2">
                     <v-btn cols="2" class="success" @click.stop="onDownload">
                         <v-icon>get_app</v-icon>
                         Export
                     </v-btn>
                 </v-col>
             </v-row>-->
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row>
                                <download-excel :fields="excelData.json_fields"
                                                :fetch="onDownload"
                                                worksheet="My Worksheet">
                                    <v-btn small class="success mx-4">
                                        <v-icon>get_app</v-icon>
                                        {{$t('download_to_excel')}}
                                    </v-btn>
                                </download-excel>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table :columns="columns"
                            :rows="rows"
                            mode="remote"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'datedOn'">
                        <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
                    </span>
                    <!--<span v-else-if="props.column.field == 'actions'">
            <v-icon small class="mr-2" @click.stop="editItem(props.row, 'HistoryForm')">edit</v-icon>
            <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
        </span>-->
                </template>
            </vue-good-table>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import downloadExcel from "vue-json-excel";
    export default {
        name: "MountaineeringHistoryList",
        props: [
            'moduleName'
        ],
        components: { downloadExcel },
        computed: {
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            },
            items() {
                return [
                    {
                        text: this.$t('report'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('modules_name.tourist_vehicle'),
                        exact: true,
                        disabled: true
                    },
                ]
            }
        },
        methods: {
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            formResponse(data) {
                console.log({data})
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },

            async onDownload() {
                let param = {
                    /* PageSize: this.serverParams.perPage,
                     PageNo: this.serverParams.page,*/
                    ReportType: this.serverParams.ReportType,
                    FromDate: this.serverParams.FromDate,
                    /* OrderType: this.serverParams.sort[0].type,
                     OrderBy: this.serverParams.sort[0].field,*/
                    /* FromDate: this.serverParams.FromDate,
                     ToDate: this.serverParams.ToDate,
                     ReportType: this.serverParams.ReportType,*/
                };
                await axios({
                    url: 'Transportation/DownloadVehicleTypecounts',
                    method: 'POST',
                    data: param
                }).then((response) => {
                    this.excelData.json_fields['VehicleCategory'] = 'VehicleCategory'
                    response.data.header.map(res => {
                        this.excelData.json_fields[res.label] = res.field
                    })
                    this.excelData.json_fields['Total'] = 'Total'
                    this.excelData.json_data = response.data.dataList
                });

                return this.excelData.json_data
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },

            async loadItems() {
                this.snackbar.isLoading = true;
                if (this.reportIsMonthWise) {
                    let param = {
                        /* PageSize: this.serverParams.perPage,
                         PageNo: this.serverParams.page,*/
                        ReportType: this.serverParams.ReportType,
                        FromDate: this.serverParams.FromDate,
                        /* OrderType: this.serverParams.sort[0].type,
                         OrderBy: this.serverParams.sort[0].field,*/
                        /* FromDate: this.serverParams.FromDate,
                         ToDate: this.serverParams.ToDate,
                         ReportType: this.serverParams.ReportType,*/
                    };
                    axios.post('NationalPark/GetNationalParkReportByMonth', param)
                        .then(response => {
                            this.rows = response.data;
                            // this.totalRecords = response.data.totalCount;
                            this.columns = []
                            this.columns.push(
                                {label: 'Protected Area', field: 'protectedArea'},
                                {label: 'January', field: 'january'},
                                {label: 'February', field: 'february'},
                                {label: 'March', field: 'march'},
                                {label: 'April', field: 'april'},
                                {label: 'May', field: 'may'},
                                {label: 'June', field: 'june'},
                                {label: 'July', field: 'july'},
                                {label: 'August', field: 'august'},
                                {label: 'September', field: 'september'},
                                {label: 'October', field: 'october'},
                                {label: 'November', field: 'november'},
                                {label: 'December', field: 'december'},
                                {label: 'Total', field: 'total'},
                            )
                            this.snackbar.loading = false;
                        })
                        .catch(err => {
                            console.log(err)
                        });
                } else {
                    let param = {
                        /* PageSize: this.serverParams.perPage,
                         PageNo: this.serverParams.page,*/
                        /* ReportType: this.serverParams.ReportType,
                         FromDate: this.serverParams.FromDate,
                         ToDate: this.serverParams.ToDate,*/
                        /* OrderType: this.serverParams.sort[0].type,
                         OrderBy: this.serverParams.sort[0].field,*/
                        /* FromDate: this.serverParams.FromDate,
                         ToDate: this.serverParams.ToDate,
                         ReportType: this.serverParams.ReportType,*/
                    };
                    axios.post('Transportation/GetVehicleTypecounts', param)
                        .then(response => {
                            this.rows = response.data.dataList;
                            //this.totalRecords = response.data.totalCount;
                            this.columns = []
                            this.columns = response.data.header
                            this.columns.unshift({
                                label: 'vehicle_category',
                                field: 'VehicleCategory'
                            })
                            this.columns.push({
                                label: 'total',
                                field: 'Total'
                            })
                            this.snackbar.loading = false;
                        })
                        .catch(err => {
                            console.log(err)
                        });
                }

            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.getNow()
            this.loadItems()
        },
        data() {
            return {
                excelData: {
                    loading: false,
                    json_fields: {},
                    json_data: [],
                    json_meta: [
                        [
                            {
                                key: "charset",
                                value: "utf-8",
                            },
                        ],
                    ],
                },
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                columns: [],
                rows: [],
                ReportTypes: [
                    {
                        id: "yearwise", value: "yearwise"
                    },
                    {
                        id: "monthwise", value: "monthwise"
                    }
                ],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    ReportType: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    /* breadcrumbsItem: [
                         {
                             text: 'Dashboard',
                             disabled: false,
                             to: 'MountaineeringDashboard',
                             exact: true

                         },
                         {
                             text: 'Mountaineering History Information',
                             disabled: true,
                         },
                     ],*/
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            };
        },
    }
</script>

<style scoped>

</style>
